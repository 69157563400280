import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalState } from './global.state';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'mlc-root',
  // templateUrl: './app.component.html',
  // styleUrls: ['./app.component.scss'],
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  // devEnvironment = !environment.production && !environment.uat;
  // uatEnvironment = environment.uat;

  // loginState = false;
  // appVersion = `${appRelease.version}-${appRelease.build}`;
  // dateValue = new Date();
  // historian$: Observable<any>;

  constructor(private _gs: GlobalState, private authService: AuthService, private router: Router) {}

  ngOnInit() {
    // this._gs.subscribe(AppVars.DIAGNOSTICS_APP_AUTH, (loginState) => {
    //   this.loginState = loginState;
    // });
  }

  // logout(): void {
  //   this.authService.logout();
  //   this.loginState = false;
  //   this._gs.notifyDataChanged(AppVars.DIAGNOSTICS_APP_AUTH, false);
  //   this.router.navigate(['/login']);
  // }
}
