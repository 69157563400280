/**
 * Utility class to manage special input text masks
 * This class would host only pure constants to be used as mask configurations
 * import MaskUtil class into any component from the SharedModule
 */
export class MaskUtil {
  public static TEL_MASK = '(000) 000-0000';
  // {
  //   mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  // };

  public static SSN_MASK = '000-00-0000';
  // {
  //   mask: [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  // };

  // public static ICD_10_CM_MASK = {
  //   mask: [/[\^U0-9]/, /[0-9]/, /[A-Z0-9]/, '.', /[A-Z0-9]/, /[A-Z0-9]/, /[A-Z0-9]/, /[A-Z]/]
  // };
}
