import { Injectable } from '@angular/core';

/**
 * Service to be used App wide which serves useable defined constants
 * for named variables to be used with AppState
 * and for event names to be used to GlobalState
 */
@Injectable()
export class AppVars {
  public static USER_ACCOUNT = 'USER_ACCOUNT';
  public static ORG_CODE = 'ORG_CODE';
  public static PATIENT_CODE = 'PATIENT_CODE';
  public static SELECTED_PATIENT_KEY = 'SELECTED_PATIENT_KEY';
  public static SELECTED_PATIENT = 'SELECTED_PATIENT';
  public static SELECTED_APPOINTMENT_KEY = 'SELECTED_APPOINTMENT_KEY';
  public static SELECTED_ORDER_KEY = 'SELECTED_ORDER_KEY';
  public static SELECTED_APPOINTMENT = 'SELECTED_APPOINTMENT';
  public static ACTIVE_DASHBOARD_TAB = 'ACTIVE_DASHBOARD_TAB_INDEX';
  public static ACTIVE_PATIENTS_ONLY = 'ACTIVE_PATIENTS_ONLY';
  public static DIAGNOSTICS_APP_AUTH = 'DIAGNOSTICS_APP_AUTH';
  public static DIAGNOSTICS_PHONE = 'DIAGNOSTICS_PHONE';
  public static DIAGNOSTICS_UID = 'DIAGNOSTICS_UID';

  public static EVENT = {
    USER_LOGGEDIN: 'USER_LOGGEDIN',
    PATIENT_SELECTED: 'PATIENT_SELECTED',
  };

  constructor() {}
}
