import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialUiModule } from './material-ui.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialTimeControlModule } from './time-control/material-time-control.module';
// import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MlcEditableFieldComponent } from './mlc-editable-field/mlc-editable-field.component';
import { MlcDisplayFieldComponent } from './mlc-display-field/mlc-display-field.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialUiModule,
    MaterialTimeControlModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [MlcEditableFieldComponent, MlcDisplayFieldComponent],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialUiModule,
    MaterialTimeControlModule,
    // TextMaskModule,
    NgxMaskModule,
    MlcEditableFieldComponent,
    MlcDisplayFieldComponent,
  ],
})
export class MlcSharedUiModule {}
