import { ExtraOptions, Routes } from '@angular/router';

import { LoginComponent } from './auth/login.component';
import { AuthGuard } from './auth/auth.guard';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },

  // AuthGuard to protect the following routes with valid Diagnostics Login
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },

  { path: '**', redirectTo: '/login' },
];

export const routeConfig: ExtraOptions = {
  useHash: false,
  enableTracing: false,
  initialNavigation: 'enabledNonBlocking',
  relativeLinkResolution: 'legacy',
};
