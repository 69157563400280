<div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px" class="auth-box show-background">
  <div class="overlay" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px" fxFlex>
    <!-- Header -->
    <div fxFlex="30" fxLayout="column" fxLayoutAlign="center center" class="header">
      <img sizes="240px" src="/assets/images/om_logo_full_h.svg" width="240" alt="OrganizeMED" />
      <div [style.color]="'white'">for DIAGNOSTICS LAB</div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex>
      <!-- Sign In / Login card -->
      <mat-card fxLayout="column" fxLayoutGap="40px">
        <mat-card-title class="card-title">Medical Diagnostics: {{ showResetPassword ? 'Recover Password' : 'SIGN IN' }}</mat-card-title>

        <mat-card-content fxLayout="column">
          <div fxFlex="40" fxLayout="column" fxLayoutGap="12px">
            <mat-form-field class="full-width">
              <mat-label>Email Address</mat-label>
              <input matInput name="email" placeholder="email address as user id" [(ngModel)]="email" />
              <mat-icon matPrefix>email</mat-icon>
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="!showResetPassword">
              <mat-label>Password</mat-label>
              <input matInput name="password" type="password" placeholder="password" [(ngModel)]="password" (keyup.enter)="login()" />
              <mat-icon matPrefix>vpn_key</mat-icon>
            </mat-form-field>
          </div>

          <div fxFlex="20">
            <p *ngIf="ackMessage">{{ ackMessage }}</p>
          </div>
        </mat-card-content>

        <mat-card-actions fxLayoutAlign="end center" *ngIf="!showResetPassword; else forgotPassword">
          <button mat-button (click)="showForgotPassword()" [disabled]="showProgress">Forgot Password?</button>
          <div fxFlex></div>

          <button mat-stroked-button color="primary" [disabled]="!email || !password" (click)="login()">
            <span *ngIf="!showProgress; else spinner">SIGN IN</span>
          </button>
        </mat-card-actions>

        <ng-template #forgotPassword>
          <mat-card-actions fxLayoutAlign="end center">
            <button mat-stroked-button color="primary" [disabled]="!email" (click)="sendPasswordResetEmail()">
              <span *ngIf="!showProgress; else spinner">SEND INSTRUCTIONS</span>
            </button>
            <button mat-button (click)="cancel()">CANCEL</button>
          </mat-card-actions>
        </ng-template>
      </mat-card>
    </div>

    <!-- Footer -->
    <footer fxFlex="10" class="footer copyright" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
      <p>Powered by</p>
      <img width="112" src="/assets/images/om_logo_full_h.svg" />
      <p>© {{ dateValue | date: 'yyyy' }}. All rights reserved.</p>
    </footer>
  </div>
</div>

<!-- Progress Indicator -->
<ng-template #spinner> <mat-spinner color="primary" diameter="20" strokeWidth="2" style="margin: 8px"></mat-spinner> </ng-template>
