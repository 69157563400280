import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
// import firebase from 'firebase/app';
// import 'firebase/auth';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppState } from './../app.service';
import { AppVars } from './../app.vars';
import { User } from './auth.user';
import { GlobalState } from '../global.state';

@Injectable()
export class AuthService {
  /**
   * User object from the /provider-users/${uid} node as an observable
   * AuthService.user is an observable available for other components to subscribe
   * When the user logs out, this would stream 'null' output
   */
  user: Observable<any>;
  private user$: BehaviorSubject<User> = new BehaviorSubject(null);
  private userAccount: User;
  private usersPath = 'diagnostics-users';

  /**
   * AuthService maps the logged in user with the User Node under /diagnostics-users
   */
  constructor(
    private _as: AppState,
    private _gs: GlobalState,
    private ngFireAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.user = this.ngFireAuth.authState.pipe(
      switchMap((currentUser) => {
        if (currentUser) {
          // console.log('[AuthService] authState: User Account is set to AppState.', this.userAccount);
          // TODO: Tidy up the usage of Subject, Observable and AppState for the same purpose
          // Map the user with full registered profile
          return this.afs
            .doc<User>(`diagnostics-users/${currentUser.uid}`)
            .valueChanges()
            .pipe(
              map((user) => {
                const cUser = {
                  uid: currentUser.uid,
                  orgCode: user.orgCode,
                  email: currentUser.email,
                  emailVerified: currentUser.emailVerified,
                  displayName: user.displayName,
                };
                this.userAccount = { ...cUser, ...user };
                console.log('[AuthService] switchMap() userAccount:', this.userAccount);
                this.user$.next(this.userAccount);
                this._as.set(AppVars.USER_ACCOUNT, this.userAccount);
                this._as.set(AppVars.DIAGNOSTICS_APP_AUTH, true);
                this._as.set(AppVars.DIAGNOSTICS_PHONE, this.userAccount.phone);
                this._as.set(AppVars.DIAGNOSTICS_UID, this.userAccount.uid);
                this._gs.notifyDataChanged(AppVars.DIAGNOSTICS_APP_AUTH, true);
                return this.userAccount;
              })
            );
        } else {
          this.user$.next(null);
          this._as.reset();
          this._gs.notifyDataChanged(AppVars.DIAGNOSTICS_APP_AUTH, false);
          this.router.navigate(['/login']); // Now force the user to the login screen
          return of(null);
        }
      })
    );
  }

  /**
   * Logout the currently logged in user and remove the user account from the App State
   */
  public logout(): void {
    this._as.reset();
    this.ngFireAuth.signOut();
    // TODO: Implement diagnostics login audit logs
  }

  getLoggedInUser(): Observable<any> {
    return this.user$.asObservable();
  }

  getOrgName(): Observable<any> {
    let orgName$ = of(null);
    if (this.userAccount.orgCode) {
      const path = `diagnostics-orgs/diagnostics-org-${this.userAccount.orgCode}`;
      this.afs
        .doc(path)
        .valueChanges()
        .pipe(map((org: any) => org?.name));
    }
    return orgName$;
  }

  /**
   * Get the provider user account's orgCode approval details
   * @param uid Optional UID for the user, default logged in UID is used
   */
  public getUserAccount(uid?: string): Observable<any> {
    // console.log('[AuthService] getUserAccount(): parameter uid = ', uid);
    const uidForPath = uid ? uid : this.userAccount.uid;
    const userNodePath = `${this.usersPath}/${uidForPath}`;
    console.log('[AuthService] getUserAccount(): userNodePath = ', userNodePath);
    if (!uidForPath) {
      throw new Error('[AuthService] - missing UID to get User Account!');
    }
    return this.afs.doc(userNodePath).valueChanges();
  }

  /**
   * Update the provider user account details under /diagnostics-users/$uid node
   * @param updateObject orgCode: string; requestDate: Date; emailVerified: boolean
   */
  public updateUserAccount(updateObject: { orgCode: string; requestDate: Date; emailVerified: boolean }): Promise<void> {
    const userNodePath = `${this.usersPath}/${this.userAccount.uid}`;
    return this.afs.doc(userNodePath).update(updateObject);
  }

  /**
   * Resend verification email to the current user's email ID
   */
  public async resendVerificationEmail(): Promise<void> {
    return (await this.ngFireAuth.currentUser).sendEmailVerification();
  }

  /**
   * Get the Organization metadata node
   * @param orgCode Organization Code to get the Org Data
   */
  public getOrgData(): Observable<any> {
    const orgCode = this.userAccount.orgCode;
    const orgPath = `/diagnostics-orgs/diagnostics-org-${orgCode}`;
    return this.afs.doc(orgPath).valueChanges();
  }

  /**
   * Update logged in user's password
   * @param password newPassword to be updated into users' auth account
   */
  public updatePassword(password: string): Promise<any> {
    if (password && password.length > 0) {
      // TODO: Implement update password??
      return null;
    } else {
      throw new Error('Error: Could not update password now. Try again later.');
    }
  }

  /**
   * Update user's Name, profile picture, phone
   * @param name Display Name of the user
   * @param photoURL (optional) URL of the profile picture/photo
   * @param phone (optional) phone number
   */
  public updateName(name: string, photoURL?: string, phone?: string): Promise<any> {
    const updateObj = {
      displayName: name,
      photoURL: photoURL || this.userAccount.photoURL,
    };
    // TODO: Implement updating profile to firestore
    return null;
  }

  /**
   * Sign In / Login the user with email & password
   * @param email registered email
   * @param password secured password
   */
  public login(email: string, password: string): Promise<any> {
    console.log('[AuthService] login():', email);
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
  }

  /**
   * When users forgets password, then send Password Reset email
   * to the registered email ID of the user
   */
  public sendPasswordResetEmail(email: string) {
    return this.ngFireAuth.sendPasswordResetEmail(email);
  }
}
