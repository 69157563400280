import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MlcSharedUiModule } from '@mlc/shared/mlc-shared-ui.module';

import { environment } from './../environments/environment';
import { AppComponent } from './app.component';
import { routeConfig, routes } from './app.routes';
import { AppState } from './app.service';
import { GlobalState } from './global.state';
import { AuthService } from './auth/auth.service';
import { LoginComponent } from './auth/login.component';
import { AuthGuard } from './auth/auth.guard';

const firebaseDb = environment.db;
const dbConfig = firebaseDb.diagnostics ? { ...firebaseDb.config, ...firebaseDb.diagnostics } : firebaseDb.config;
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MlcSharedUiModule,
    RouterModule.forRoot(routes, routeConfig),
    AngularFireModule.initializeApp(dbConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
  ],
  declarations: [LoginComponent, AppComponent],
  providers: [AuthGuard, AppState, GlobalState, AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {}
