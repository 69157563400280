/*
 *  Flatt - tool to flatten data in the objects
 *
 */

export class FlattUtil {
  static separator = '_';
  static set(obj, path, value) {
    const keys = path.split(FlattUtil.separator);
    let key = 'obj[ keys[0] ]';

    for (let i = 1; i < keys.length; i++) {
      // tslint:disable-next-line:no-eval
      if (eval('typeof ' + key) !== 'object') {
        // tslint:disable-next-line:no-eval
        eval(key + ' = {}');
      }
      key = key + '[ keys[' + i + '] ]';
    }

    // tslint:disable-next-line:no-eval
    eval(key + '=value');
    return obj;
  }
  static sets(_obj, values): Object {
    for (const __path in values) {
      if (values.hasOwnProperty(__path)) {
        FlattUtil.set(_obj, __path, values[__path]);
      }
    }
    return _obj;
  }

  static get(obj, path) {
    return FlattUtil.is(obj)[path];
  }

  static is(object) {
    return Object.assign(
      {},
      ...(function _flatten(child, path = []) {
        return [].concat(
          ...Object.keys(child).map(
            key =>
              typeof child[key] === 'object'
                ? _flatten(child[key], path.concat([key]))
                : { [path.concat([key]).join(FlattUtil.separator)]: child[key] }
          )
        );
      })(object)
    );
  }
}
