import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MaskUtil } from '@mlc/shared/utils';
import { Subscription } from 'rxjs';

import { AppState } from './../app.service';
import { GlobalState } from './../global.state';
import { AuthService } from './auth.service';

@Component({
  selector: 'mlc-historian-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  dateValue: Date = new Date();
  telMask = MaskUtil.TEL_MASK;
  showProgress = false;
  showResetPassword = false;
  ackMessage: string;

  dashboardPath = '/dashboard';
  email: string;
  password: string;

  userSub: Subscription;

  constructor(private router: Router, private authService: AuthService, private _gs: GlobalState, private _as: AppState) {}

  ngOnInit() {
    // Login if valid user session is available, manage user profile
    this.userSub = this.authService.user.subscribe((user) => {
      console.log('[LoginComponent] init():', user);
      if (user && user.uid) {
        this.router.navigate([this.dashboardPath]);
      } else {
        this._as.reset();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  // Login with Email & Password
  login() {
    console.log('[LoginComponent] login():', this.email, this.password);
    if (!this.email || !this.password) {
      return;
    }
    this.ackMessage = '';
    this.showProgress = true;
    this.authService
      .login(this.email, this.password)
      // .then((res) => console.log('[LoginComponent] auth login result =', res))
      .catch((err) => {
        this.ackMessage = 'ERROR! Could not login! Invalid credentials.';
        setTimeout(() => {
          this.ackMessage = '';
        }, 2500);
        console.log('ERROR! [LoginComponent] login(): ', err);
      })
      .finally(() => (this.showProgress = false));
  }

  showForgotPassword(): void {
    this.showResetPassword = true;
  }

  sendPasswordResetEmail(): void {
    this.showProgress = true;
    this.authService
      .sendPasswordResetEmail(this.email)
      .then(() => {
        this.ackMessage = 'Please check your inbox for Password Recovery Instructions!';
        setTimeout(() => {
          this.cancel();
        }, 8000);
      })
      .catch((error) => {
        this.ackMessage = 'Could not verify your account! Please contact your Account Administrator for additional help!';
      })
      .finally(() => {
        this.showProgress = false;
      });
  }

  cancel(): void {
    this.ackMessage = '';
    this.showProgress = false;
    this.showResetPassword = false;
  }
}
