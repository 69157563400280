import { Injectable } from '@angular/core';

export interface InternalStateType {
  [key: string]: any;
}

@Injectable()
export class AppState {
  private _state: InternalStateType = {};

  constructor() {}

  // already return a clone of the current state
  get state() {
    return (this._state = this._clone(this._state));
  }

  // never allow mutation
  set state(value) {
    throw new Error('do not mutate the `.state` directly');
  }

  get(prop?: any) {
    // use our state getter for the clone
    const state = this.state;
    // return state.hasOwnProperty(prop) ? state[prop] : state;
    return state.hasOwnProperty(prop) ? state[prop] : null;
  }

  set(prop: string, value: any) {
    // internally mutate our state
    return (this._state[prop] = value);
  }

  reset() {
    // remove all set properties of state and reinitialize state
    return (this._state = {});
  }

  private _clone(object: InternalStateType) {
    // simple object clone
    return JSON.parse(JSON.stringify(object));
  }
}
